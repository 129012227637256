import VideoWorkerCommon from "./VideoWorkerCommon";

class VideoWorker {
  constructor(glContext, videoElementId, options) {
    // WebGL Context
    this.gl = glContext;

    this.videoElementId = videoElementId;

    // Common functions
    this.videoWorkerCommon = new VideoWorkerCommon();

    // Bind Functions
    this.openVideo              = this.openVideo.bind(this);
    this.update                 = this.update.bind(this);
    this.isReady                = this.isReady.bind(this);
    this.addToVideoBuffer       = this.addToVideoBuffer.bind(this);
    this.play                   = this.play.bind(this);
    this.pause                  = this.pause.bind(this);
    this.seekTo                 = this.seekTo.bind(this);
    this.setTotalSegments       = this.setTotalSegments.bind(this);
    this.getCurrentSegment      = this.getCurrentSegment.bind(this);
    this.getPlaybackPercentage  = this.getPlaybackPercentage.bind(this);
    this.getRemainingFrames     = this.getRemainingFrames.bind(this);
    this.updateVideoTexture     = this.updateVideoTexture.bind(this);
    this.clearWorker            = this.clearWorker.bind(this);
    this.canPlayFromCurrentTime = this.canPlayFromCurrentTime.bind(this);
    this.destroy                = this.destroy.bind(this);

    // Internal Events
    this.onVideoPlayerError         = this.onVideoPlayerError.bind(this);
    this.onVideoPlayerCanPlay       = this.onVideoPlayerCanPlay.bind(this);
    this.onMediaSourceOpen          = this.onMediaSourceOpen.bind(this);
    this.onSourceBufferUpdateEnd    = this.onSourceBufferUpdateEnd.bind(this);
    this.onSourceBufferError        = this.onSourceBufferError.bind(this);

    // Playback Management
    this.previousVideoPlayerTime = 0.0;
    this.previousVideoPlayerClock = 0.0;
    this.isPlaying = false;
    this.isSeeking = false;

    // Frame Decoding
    this.decodedFrameIndex = 0;
    this.lastDecodedFrameIndex = 0;
    this.frameBuffer = 0;
    this.frameBufferTexture = 0;

    // Video Management
    this.loadedVideo = false;
    this.videoPlayerCanPlay = false;
    this.videoPlayer = 0;
    this.mediaSource = 0;
    this.mimeCodec = "";
    this.duration = 0;
    this.mediaSourceOpen = false;   
    this.sourceBuffer = 0;
    this.videoDataBuffer = [];
    this.sourceBufferReady = false;
    this.lastAddedVideoIndex = 0;
    this.totalSegments = 0;
    this.isBuffering = false;
    this.framesPerSegment = 0;
    this.framesPerSecond = 0;
    
    this.autoPaused = false;
    this.bufferedSegments = [];

    this.canUpdate = true;
    this.createdVideoPlayer = false;
    
    this.debugEnabled = options.debugEnabled ? options.debugEnabled : false;
  }

  onVideoPlayerError(err)
  {
    console.log("Video Player Error: ");
    console.log(err);
    console.log("Code: " + this.videoPlayer.error.code);
    console.log(this.videoPlayer.error.message);
  }

  onVideoPlayerCanPlay()
  {
    this.videoPlayerCanPlay = true;
    this.isSeeking = false;
  }

  clearWorker()
  {
    if (this.mediaSource != 0)
    {
        for (var i = 0; i < this.mediaSource.sourceBuffers.length; ++i)
        {
            this.mediaSource.sourceBuffers[i].abort();
        }
        if (this.mediaSource.readyState === "open"){
            this.mediaSource.endOfStream();
        }
    }

    // Reset state
    this.loadedVideo = false;
    this.videoPlayerCanPlay = false;
    this.mediaSource = 0;
    this.mimeCodec = "";
    this.duration = 0;
    this.mediaSourceOpen = false;   
    this.sourceBuffer = 0;
    this.videoDataBuffer = [];
    this.sourceBufferReady = false;
    this.lastAddedVideoIndex = 0;
    this.totalSegments = 0; 
    this.previousVideoPlayerTime = 0;
    this.decodedFrameIndex = 0;
    this.lastDecodedFrameIndex = 0;
  }

  // Note (rbrt): Checks buffered segments instead of simply querying the html5 video player. This is
  // important because we need to ensure our mesh data is also sufficiently buffered, and in
  // the HLS path this is not sufficiently guaranteed by having enough video buffered.
  canPlayFromCurrentTime(){
    let frame = Math.floor(this.videoPlayer.currentTime * this.framesPerSecond);
    let segment =  Math.floor(frame / this.framesPerSegment) + 1;

    if (this.bufferedSegments[segment] === false){
        return false;
    }

    return true;
  }

  openVideo(mimeCodec, duration)
  {
    this.clearWorker();

    this.videoPlayer = document.getElementById(this.videoElementId);
    if (!this.videoPlayer)
    {
        this.videoPlayer = document.createElement("video");
        this.videoPlayer.setAttribute("id", this.videoElementId);
        this.videoPlayer.setAttribute("width", "1");
        this.videoPlayer.setAttribute("height", "1");
        this.videoPlayer.setAttribute("style", "position: absolute;");
        this.videoPlayer.setAttribute("controls", null);
        document.body.appendChild(this.videoPlayer);

        this.createdVideoPlayer = true;
    }

    this.mimeCodec = mimeCodec;
    this.duration = duration;

    // Setup Video Player
    this.videoPlayer.addEventListener('error', this.onVideoPlayerError);
    this.videoPlayer.addEventListener('canplay', this.onVideoPlayerCanPlay);

    // Setup Media Source
    this.mediaSource = new MediaSource();
    this.mediaSource.addEventListener('sourceopen', this.onMediaSourceOpen);

    // Attach media source to video element
    this.videoPlayer.src = URL.createObjectURL(this.mediaSource);
    this.videoPlayer.currentTime = 0.0;
    this.loadedVideo = true;
    this.autoPaused = false;
    this.isPlaying = false;
  }

  onMediaSourceOpen()
  {
    // Setup source buffer.
    this.sourceBuffer = this.mediaSource.addSourceBuffer(this.mimeCodec);
    this.sourceBuffer.addEventListener('updateend', this.onSourceBufferUpdateEnd);
    this.sourceBuffer.addEventListener('error', this.onSourceBufferError);
    this.sourceBuffer.id = `${Date.now()}-${Math.random()}`;

    this.mediaSource.duration = this.duration;
    this.mediaSourceOpen = true;
    this.sourceBufferReady = true;
  }

  hasBufferedSegmentForCurrentTime()
  {
    for (let i = 0; this.sourceBuffer.buffered !== undefined && i < this.sourceBuffer.buffered.length; i++){
        if (this.videoPlayer.currentTime >= this.sourceBuffer.buffered.start(i) && this.videoPlayer.currentTime <= this.sourceBuffer.buffered.end(i)){
            return true;
        }
    }

    return false;
  }

  update()
  {
    // Do not run the update loop if the video is not ready, or if 
    // the VideoWorker has been externally locked.
    if (!this.loadedVideo){
        return;
    }
    if (!this.canUpdate){
        return;
    }

    this.updating = true;

    if (!this.canPlayFromCurrentTime()){
        // Pause if we don't have enough to play
        if (this.isPlaying && this.pause()){
            this.autoPaused = true;
        }
    }
    else{
        // Unpause if we paused because of not having enough content cached
        if (!this.isPlaying && this.autoPaused && this.play()){
            this.autoPaused = false;
        }
    }

    // Check if we have pending source buffers to add to the MediaSource.
    if (this.sourceBufferReady && this.videoDataBuffer.length > 0){
        let buffer = this.videoDataBuffer.shift();

        if (!this.sourceBuffer.updating && this.mediaSource.readyState === "open" && this.sourceBuffer.appendBuffer){
            this.sourceBuffer.appendBuffer(buffer.data);
            this.sourceBufferReady = false;
            this.lastAddedVideoIndex = buffer.index;
            this.bufferedSegments[buffer.index] = true;

            if (this.debugEnabled){
              console.log("Appended buffer " + buffer.index);
            }
        }
        else{
            this.videoDataBuffer.unshift(buffer);
        }
    }

    this.isBuffering = false;

    // Check playback to see if we hit the end of the source buffer.
    if (this.isPlaying){
        let currentVideoSegment = this.getCurrentSegment();
        let previousSegment = this.getCurrentSegmentForTime(this.previousVideoPlayerTime);

        // Note (rbrt): When we advance to a new segment, remove the previous segment's data from the SourceBuffer.
        // This prevents the Browser from manually managing the SourceBuffer's memory, which can have unpredictable
        // results between browsers and potentially remove data we still require.
        //
        // TODO: Currently assumes 2 second (60 frame 30fps) chunks. Generalize.
        if (previousSegment !== currentVideoSegment && !this.sourceBuffer.updating){
            let endTime = Math.max(this.previousVideoPlayerTime - 2.1, 0);
            let startTime = Math.max(0, endTime - 2.0);

            if (endTime > startTime){
                if (this.sourceBuffer && this.sourceBuffer.remove){
                    // Disable this for now; it's causing more problems than it's solving and the
                    // previously observed issue is no longer occurring. Leave here in case we 
                    // want to revisit this for reducing memory pressure in the browser.
                    //this.sourceBuffer.remove(startTime, endTime);
                }
            }
        }

        if (Date.now() - this.previousVideoPlayerClock > (1000.0 / 15.0)){
            if (this.videoPlayer.currentTime === this.previousVideoPlayerTime && currentVideoSegment >= (this.totalSegments - 1)){
                this.videoPlayer.currentTime = 0.0;
            } 
            else {
                if (this.videoPlayer.currentTime === this.previousVideoPlayerTime && this.videoPlayerCanPlay){
                    this.isBuffering = true;
                }
            }

            this.previousVideoPlayerClock = Date.now();
            this.previousVideoPlayerTime = this.videoPlayer.currentTime;
        }
    }

    this.updating = false;
  }

  isReady()
  {
    if (this.mediaSourceOpen && this.canUpdate)
    {
        return true;
    }

    return false;
  }

  isVideoPlayerPlaying()
  {
    // Check used to prevent "The play() request was interrupted by a call to pause()"
    return this.videoPlayer.currentTime > 0 && !this.videoPlayer.paused && !this.videoPlayer.ended
    && this.videoPlayer.readyState > this.videoPlayer.HAVE_CURRENT_DATA;
  }

  addToVideoBuffer(index, data, url)
  {
    this.videoDataBuffer.push(
    {
        index: index,
        data: data,
        url: url
    });
  }

  onSourceBufferUpdateEnd(event)
  {
    this.sourceBufferReady = true;
  }

  onSourceBufferError(event)
  {
    console.log("Source Buffer Error: ");
    console.log(event);
  }

  play()
  {
      if (!this.isVideoPlayerPlaying()) {
          this.videoPlayer.play();      
          this.isPlaying = true;
          return true;
      }
      return false;
  }

  pause()
  {
      if (this.isVideoPlayerPlaying()){
          this.videoPlayer.pause();
          this.isPlaying = false;
          return true;
      }
      return false;
  }

  seekTo(progress)
  {
    if (isNaN(this.mediaSource.duration)){
        return;
    }
    this.isSeeking = true;
    this.videoPlayer.currentTime = this.mediaSource.duration * progress;
  }

  setTotalSegments(count)
  {
    this.totalSegments = count;
    this.bufferedSegments = [];
    for (let i = 0; i < this.totalSegments; i++){
        this.bufferedSegments.push(false);
    }
  }

  getCurrentSegment()
  {
    return this.getCurrentSegmentForTime(this.videoPlayer.currentTime);
  }

  getCurrentSegmentForTime(time)
  {
    return Math.floor(time / this.videoPlayer.duration * this.totalSegments);
  }

  getPlaybackPercentage()
  {
    var frameTime = this.decodedFrameIndex / parseFloat(this.framesPerSecond);
    let percentage = frameTime / this.duration;
    if (isFinite(percentage)){
        return percentage;
    }
    else{
        return 0;
    }
  }

  getRemainingFrames()
  {
      if (this.framesPerSecond && this.duration && this.decodedFrameIndex) {
          return this.framesPerSecond * this.duration - this.decodedFrameIndex;
      }
      // this.mediaSource.duration can hold the correct duration when this.duration is 0 or null
      if (this.framesPerSecond && this.mediaSource && this.mediaSource.duration && this.decodedFrameIndex) {
           return this.framesPerSecond * this.mediaSource.duration - this.decodedFrameIndex;
      }     
      // estimate remaining frames as 0 when 99% of clip was played if we can't calculate it.
      if (this.getPlaybackPercentage() && this.getPlaybackPercentage() > 0.99) {
          console.log("end estimated using getPlaybackPercentage", this.getPlaybackPercentage());
          return 0;
      } 
      return null;
  }

  updateVideoTexture(textureObject)
  {
    let texture = textureObject.texture;
    
    // This state seems to be manipulated by ThreeJS so make sure we explicitly set it.
    this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, true);

    // Make sure our framebuffer is setup correctly.
    if (this.frameBuffer == 0 || this.frameBufferTexture == 0){
        this.frameBuffer = this.gl.createFramebuffer();

        this.frameBufferTexture = this.gl.createTexture();
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.frameBufferTexture);
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, new Uint8Array([255, 0, 255, 255]));
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);

        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);
        this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, 
            this.gl.COLOR_ATTACHMENT0,
            this.gl.TEXTURE_2D, 
            this.frameBufferTexture, 
            0);

        // check if you can read from this type of texture.
        var canRead = (this.gl.checkFramebufferStatus(this.gl.FRAMEBUFFER) == this.gl.FRAMEBUFFER_COMPLETE);
        if (!canRead){
            console.log("WebGL Error: framebuffer cannot be read.");
        }

        // Unbind the framebuffer
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    }

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.frameBufferTexture);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, this.videoPlayer);

    // Read back pixels for frame decoding.
    if (this.frameBuffer != 0 && (this.videoPlayer.readyState === this.videoPlayer.HAVE_ENOUGH_DATA || this.videoPlayer.readyState === this.videoPlayer.HAVE_FUTURE_DATA)){
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);

        // Subtract 100 because binary pixels occupy 96 (3 * 32) pixels, and there are 4
        // more pixels of padding, for a total of 100.
        // NOTE: due to gl.UNPACK_FLIP_Y_WEBGL we read from the top, not the bottom.
        var pixels = new Uint8Array(96 * 4);
        this.gl.readPixels(this.videoPlayer.videoWidth - 100, 2, 96, 1, this.gl.RGBA, this.gl.UNSIGNED_BYTE, pixels);
        this.decodedFrameIndex = this.videoWorkerCommon.decodeFrameIndex(pixels);
        // HACK: this is a small cost hack that basically uses the playback timer to estimate what the 
        // decoded frame number *should* be. If its too far off it's very likely something is behaving
        // strangely with the browsers video/webgl implementation. We skip any non-sensical frames.
        let estimateFrameNumber = this.videoPlayer.currentTime * this.framesPerSecond;
        if (Math.abs(estimateFrameNumber - this.decodedFrameIndex) < 3){
            this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
            this.gl.copyTexImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, 0, 0, this.videoPlayer.videoWidth, this.videoPlayer.videoHeight, 0);
            this.lastDecodedFrameIndex = this.decodedFrameIndex;
        } 
        else {
            this.decodedFrameIndex = this.lastDecodedFrameIndex;
        }

        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    }
  }

  setFrameInformation(framesPerSegment, framesPerSecond)
  {
    this.framesPerSegment = framesPerSegment;
    this.framesPerSecond = framesPerSecond;
  }

  destroy(){
    if (this.createdVideoPlayer){
        this.videoPlayer.parentNode.removeChild(this.videoPlayer);
        this.videoPlayer = 0;
    }

    this.clearWorker();
  }
}

export default VideoWorker;