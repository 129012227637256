/*


usage:

p = new Player({
  useWorker: <bool>,
  workerFile: <defaults to "Decoder.js"> // give path to Decoder.js
  webgl: true | false | "auto" // defaults to "auto"
});

// canvas property represents the canvas node
// put it somewhere in the dom
p.canvas;

p.webgl; // contains the used rendering mode. if you pass auto to webgl you can see what auto detection resulted in

p.decode(<binary>);


*/

import Decoder from "./Decoder.js";

// const decoder = Decoder;
// console.log(decoder);

// universal module definition
const player = function (root, factory) {
    // if (typeof define === 'function' && define.amd) {
    //     // AMD. Register as an anonymous module.
    //     define(["./Decoder", "./YUVCanvas"], factory);
    // } else if (typeof exports === 'object') {
    if (typeof exports === 'object') {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory(require("./Decoder"), require("./YUVCanvas"));
    } 
    else {
        // Browser globals (root is window)
        //root.Player = factory(root.Decoder, root.YUVCanvas);
        return factory(Decoder, "./YUVCanvas");
    }
}(this, function (decoder, WebGLCanvas) {
  "use strict";
  var nowValue = Decoder.nowValue;

  var Player = function(parOptions){
    var self = this;
    this._config = parOptions || {};
    
    this.render = true;
    if (this._config.render === false){
      this.render = false;
    }

    this.chunk = parOptions.chunk;
    this.chunkIndex = parOptions.chunkIndex;

    this.nowValue = nowValue;

    
    
    this._config.workerFile = this._config.workerFile || "Decoder.js";
    if (this._config.preserveDrawingBuffer){
      this._config.contextOptions = this._config.contextOptions || {};
      this._config.contextOptions.preserveDrawingBuffer = true;
    }
    
    var webgl = "auto";
    if (this._config.webgl === true){
      webgl = true;
    }else if (this._config.webgl === false){
      webgl = false;
    }
    
    if (webgl == "auto"){
      webgl = true;
      try{
        if (!window.WebGLRenderingContext) {
          // the browser doesn't even know what WebGL is
          webgl = false;
        } 
      }catch(e){
        webgl = false;
      }
    }
    
    this.webgl = webgl;

    this.onCompletedVideo = undefined;
    

    var lastWidth;
    var lastHeight;
    var onPictureDecoded = function(buffer, width, height, infos) {
      self.onPictureDecoded(buffer, width, height, infos);

      if (self.currentChunk === undefined || this.chunk === undefined){
        return;
      }

      var startTime = nowValue();
      this.chunk.cachedFrames.push({
        data: new Uint8Array(buffer.buf, 0, buffer.length), 
        dimensions: this.chunk.dimensions
      });

      buffer.buf = null;
      buffer = null;
      if (infos[0].currentFrame >= (infos[0].frameCount - 1) && this.onCompletedVideo){
        this.onCompletedVideo();
      }
    };
    
    // provide size
    
    if (!this._config.size){
      this._config.size = {};
    }
    this._config.size.width = this._config.size.width || 200;
    this._config.size.height = this._config.size.height || 200;

    this.initializedWorkers = false;
    this.initializedWorkersCount = 2;

    if (this._config.useWorker){
      this.workerIndex = 0;
      this.workers = parOptions.workers;

      let workerParent = this;

      for (let i = 0; i < workerParent.workers.length; i++){
        workerParent.workers[i].id = i;
        if (!workerParent.workers[i].hasHandler){
          parOptions.workers[0].addEventListener('message', function(e) {
            var data = e.data;
            if (data.consoleLog){
              workerParent.initializedWorkersCount--;
              return;
            }

            workerParent.workers[i].hasHandler = true;
            
            onPictureDecoded.call(self, data, data.width, data.height, data.infos);
          }, false);

          workerParent.workers[i].postMessage({type: "Broadway.js - Worker init", index: i, options: {
            rgb: !webgl,
            memsize: this.memsize,
            reuseMemory: this._config.reuseMemory ? true : false
          }});
        }

      }
      
      if (this._config.transferMemory){
        this.decode = function(parData, parInfo){
          // no copy
          // instead we are transfering the ownership of the buffer
          // dangerous!!!
          
          parOptions.workers[0].postMessage({buf: parData.buffer, offset: parData.byteOffset, length: parData.length, info: parInfo}, [parData.buffer]); // Send data to our worker.
        };
        
      }else{
        this.decode = function(parData, parInfo){
          // Copy the sample so that we only do a structured clone of the
          // region of interest
          let startTime = parInfo ? parInfo.startTime : 0;
          if (parInfo !== undefined && parInfo.currentFrame === 0){
            let currentTime = ((Date.now() - parInfo.startTime) / 1000.0);
          }

          var copyU8 = new Uint8Array(parData.length);
          copyU8.set( parData, 0, parData.length );
          parOptions.workers[0].postMessage({buf: copyU8.buffer, offset: 0, length: parData.length, info: parInfo, index:workerParent.workerIndex, startTime: startTime}, [copyU8.buffer]); // Send data to our worker.
        };
        
      }
      
      if (this._config.reuseMemory){
        this.recycleMemory = function(parArray){
          //this.beforeRecycle();
          parOptions.workers[0].postMessage({reuse: parArray.buffer}, [parArray.buffer]); // Send data to our worker.
          //this.afterRecycle();
        };
      }
      
    }
    else{
      console.log("No workers");
      this.decoder = new Decoder({
        rgb: !webgl
      });
      this.decoder.onPictureDecoded = onPictureDecoded;

      this.decode = function(parData, parInfo){
        self.decoder.decode(parData, parInfo);
      };
      
    }
    
    lastWidth = this._config.size.width;
    lastHeight = this._config.size.height;
    
  };

  Player.prototype = {
    
    onPictureDecoded: function(buffer, width, height, infos){},
    
    // call when memory of decoded frames is not used anymore
    recycleMemory: function(buf){
    },
    /*beforeRecycle: function(){},
    afterRecycle: function(){},*/
  };

  return Player;
  
});


export default player;
