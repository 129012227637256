// ------------------------------------------------
// BASIC THREEJS SETUP
// ------------------------------------------------

import * as THREE from "three";
import { Broadway } from "./broadway-master/Player/mp4";

import fragmentShaderMesh from './shaders/fragment_shader.glsl';
import vertexShaderMesh from './shaders/vertex_shader.glsl';
import fragmentShaderShadow from './shaders/fragment_shader_shadow.glsl';
import vertexShaderShadow from './shaders/vertex_shader_shadow.glsl';
import fragmentShaderiOS from "./shaders/fragment_shader_yuv_to_rgb.glsl";
import vertexShaderiOS from "./shaders/vertex_shader_yuv_to_rgb.glsl";

function fillArray(dst, src) {
  for (var i = 0; i < src.length; i++) {
    dst[i] = src[i];
  }
}

class ThreeWrapper {
  constructor(holostreamCanvas, options) {
    let threeScene = options.threeScene;
    let threeCamera = options.threeCamera;
    let threeRenderer = options.threeRenderer;
    this.threeCanvas = holostreamCanvas;
    this.overrideRender = (options.overrideRender !== undefined) ? options.overrideRender : false;

    this.getWebGLContext      = this.getWebGLContext.bind(this);
    this.onKeyframeReady      = this.onKeyframeReady.bind(this);
    this.onFrameReady         = this.onFrameReady.bind(this);
    this.startRender          = this.startRender.bind(this);
    this.render               = this.render.bind(this);
    this.getMeshTexture       = this.getMeshTexture.bind(this);
    this.updateMeshTexture    = this.updateMeshTexture.bind(this);
    this.initializeYUVTexture = this.initializeYUVTexture.bind(this);
    this.updateYUVTexturePos  = this.updateYUVTexturePos.bind(this);
    this.reinitializeMaterial = this.reinitializeMaterial.bind(this);
    this.destroy              = this.destroy.bind(this);

    this.initialVertexBufferCount = 45000;
    this.initialIndexBufferCount = 120000;
    this.sequenceVertexBufferCount = this.initialVertexBufferCount;
    this.sequenceIndexBufferCount = this.initialIndexBufferCount;

    // Events
    this.onResize = this.onResize.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);

    // Create an empty scene
    this.fillColor = new THREE.Color("#aaa");

    // Create a basic perspective camera
    if (threeCamera){
        this.camera = threeCamera;
    }
    else{
        let w = this.threeCanvas.parentElement.offsetWidth;
        let h = this.threeCanvas.parentElement.offsetHeight;
        this.camera = new THREE.PerspectiveCamera(45, w / h, 0.001, 1000 );
        this.camera.position.z = 3.5;
        this.camera.position.y = 2;
    }

    // Create a renderer with Antialiasing
    this.createdRenderer = false;
    this.canvasParent = this.threeCanvas.parentElement;
    if (!threeRenderer){
        let w = this.threeCanvas.parentElement.offsetWidth;
        let h = this.threeCanvas.parentElement.offsetHeight;

        this.renderer = new THREE.WebGLRenderer({antialias:true, alpha: true, canvas: this.threeCanvas});
        this.renderer.setSize(w, h);
        this.canvasParent.appendChild(this.threeCanvas);

        this.renderer.setClearColor("#000000");
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.createdRenderer = true;
    }
    else{
        this.renderer = threeRenderer;
    }

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.createdScene = false;
    if (threeScene){
        this.scene = threeScene;
    }
    else{
        this.scene = new THREE.Scene();
        this.scene.background = this.fillColor;
        this.scene.fog = new THREE.FogExp2(this.fillColor, 0.05);

        // LIGHTS SETUP
        var ambientLight = new THREE.AmbientLight( 0x222222 );
        this.scene.add( ambientLight );
        this.createdScene = true;
    }

    // Lighting
    this.lightingEnabled = false;
    if (options.lightingEnabled)
    {
      this.lightingEnabled = options.lightingEnabled;
    }

    this.texture = 0;
    this.yuvTextures = 0;
    this.yuvTexturePos = 0;
    this.boneTex = 0;

    this.mesh = 0;
    this.geometry = new THREE.BufferGeometry();
    this.material = 0;
    this.boneArray = [];
    this.playing = false;
    this.nextFrame = 0;
    this.previousTime = 0;

    this.softwarePath = false;
    this.textureHeight = 0;
    this.textureWidth = 0;
  }

  getWebGLContext()
  {
    return this.renderer.getContext();
  }

  onClick()
  {
    this.controls.autoRotate = false;
  }

  onMouseUp()
  {
    this.lastInputTime = Date.now();
  }

  getMobileOperatingSystem = function () {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Mobile";
    }

    if (/android/i.test(userAgent)) {
        return "Mobile";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "Mobile";
    }

    return "unknown";
  }

  onResize()
  {
    if (this.canvasParent === undefined){
        return;
    }

    var w = this.canvasParent.offsetWidth;
    var h = this.canvasParent.offsetHeight;

    this.threeCanvas.width = w;
    this.threeCanvas.height = h;

    this.camera.aspect = this.threeCanvas.width / this.threeCanvas.height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.threeCanvas.width, this.threeCanvas.height);

    let clipArea = document.getElementById("ClipSelectContainer");
    if (clipArea){
        clipArea.style.height = this.canvasParent.offsetHeight + "px";
    }
  }

  allocateNewGeometryBuffers()
  {
    let indices    = new Float32Array(this.sequenceIndexBufferCount);
    let vertices   = new Float32Array(this.sequenceVertexBufferCount * 3);
    let normals    = new Float32Array(this.sequenceVertexBufferCount * 3);
    let uvs        = new Float32Array(this.sequenceVertexBufferCount * 2);
    let skinIndex  = new Float32Array(this.sequenceVertexBufferCount * 4);
    let skinWeight = new Float32Array(this.sequenceVertexBufferCount * 4);

    let indicesAttribute = new Uint32Array(indices);

    // Setup Indices and Vertex Attributes.
    this.geometry.setIndex( new THREE.BufferAttribute(indicesAttribute, 1) );
    this.geometry.setAttribute( 'position',   new THREE.Float32BufferAttribute( vertices, 3 ) );
    this.geometry.setAttribute( 'normal',     new THREE.Float32BufferAttribute( normals, 3 ) );
    this.geometry.setAttribute( 'uvs',        new THREE.Float32BufferAttribute( uvs, 2 ) );
    this.geometry.setAttribute( 'skinIndex',  new THREE.Float32BufferAttribute( skinIndex, 4 ) );
    this.geometry.setAttribute( 'skinWeight', new THREE.Float32BufferAttribute( skinWeight, 4 ) );
  }

  onKeyframeReady(sequence)
  {
    // Populate with empty data in the event we are passed an empty sequence to avoid issues inside ThreeJS
    if (sequence.vertices === undefined || sequence.vertices.length === 0){
      this.allocateNewGeometryBuffers();
      return;
    }
    else if (sequence.vertices.length >= this.sequenceVertexBufferCount){
      this.sequenceVertexBufferCount = parseInt(sequence.vertices.length * 1.2);
      this.sequenceIndexBufferCount = parseInt(sequence.indices.length * 1.2);
      
      // Added for MMA data, but problematic with Jupiter data. Disabling for now.
      //this.allocateNewGeometryBuffers();
    }

    // Update Positions
    this.geometry.attributes.position.array = sequence.vertices;
    this.geometry.attributes.position.needsUpdate = true;

    // Update Normals
    if (sequence.normal_count > 0)
    {
      this.geometry.attributes.normal.array = sequence.normals;
      this.geometry.attributes.normal.needsUpdate = true;
    }

    // Update lighting uniform
    let canUseLighting = this.lightingEnabled && (sequence.normal_count > 0);
    this.material.uniforms.lightingEnabled = {value: canUseLighting ? 1.0 : 0.0};

    if (sequence.normal_count > 0){
      this.geometry.hasNormals = true;
    }

    // Update UVS
    this.geometry.attributes.uvs.array = sequence.uvs;
    this.geometry.attributes.uvs.needsUpdate = true;

    // Update Skin Indices
    this.geometry.attributes.skinIndex.array = sequence.bone_indices;
    this.geometry.attributes.skinIndex.needsUpdate = true;

    // Update Skin Weights
    this.geometry.attributes.skinWeight.array = sequence.bone_weights;
    this.geometry.attributes.skinWeight.needsUpdate = true;

    // Update Indices
    this.geometry.setIndex( new THREE.BufferAttribute(sequence.indices, 1) );
    this.geometry.index.needsUpdate = true;
  }

  onFrameReady(sequence, boneMatrices, textureData){
    // Update bone matrices.
    if (sequence.ssdr_frame_count > 0)
    {
        let boneData = new Float32Array(2048);
        fillArray(boneData, boneMatrices);

        if (this.boneTex === 0){
            let data = [];
            for (let i = 0; i < 2048; i++){
                data.push(0);
            }
            this.boneTex = new THREE.DataTexture(new Float32Array(data), 512, 1, THREE.RGBAFormat, THREE.FloatType);
            this.boneTex.needsUpdate = true;
        }

        this.boneTex.image.data = boneData;
        this.boneTex.needsUpdate = true;
    }
  }

  getShader(url)
  {
    // TODO: will eventually need a better system since blocking
    // XMLHttpRequests are being deprecated.
    var xhr = null;
    xhr = new XMLHttpRequest();
    xhr.open( "GET", url, false );
    xhr.send( null );

    function replaceThreeChunkFn(a, b) {
        return THREE.ShaderChunk[b] + '\n';
    }

    function shaderParse(glsl) {
        return glsl.replace(/\/\/\s?chunk\(\s?(\w+)\s?\);/g, replaceThreeChunkFn);
    }

    return shaderParse(xhr.responseText);
  }

  startRender(){
    this.allocateNewGeometryBuffers();

    // Fill bone array with 128 matrices.
    for (var i = 0; i < 128; ++i)
    {
        this.boneArray.push(new THREE.Matrix4());
    }

    if (this.boneTex === 0){
        let data = [];
        for (let i = 0; i < 2048; i++){
            data.push(0);
        }
        this.boneTex = new THREE.DataTexture(new Float32Array(data), 512, 1, THREE.RGBAFormat, THREE.FloatType);
        this.boneTex.needsUpdate = true;
    }
    // Texture
    var data = new Uint8Array(4);
    data[0] = 255;
    data[1] = 0;
    data[2] = 255;
    data[3] = 255;

    // HACK (rbrt): Three changed DataTexture to use texStorage2D instead of
    // texImage2D. We trick the DataTexture into using texImage2D, so that we
    // can copy into the texture appropriately, by marking it as a VideoTexture
    // and assigning it an empty update function so that it does not explode when
    // Three tries to call the non-existent function.
    this.texture = new THREE.DataTexture(data, 1, 1, THREE.RGBAFormat);
    this.texture.update = () => {};
    this.texture.isVideoTexture = true;

    this.texture.minFilter = THREE.LinearFilter;
    this.texture.magFilter = THREE.LinearFilter;

    this.texture.needsUpdate = true;

    var uniforms = THREE.UniformsUtils.merge( [

        THREE.UniformsLib[ "ambient" ],
        THREE.UniformsLib[ "lights" ],
        THREE.UniformsLib[ "shadowmap" ],
    ] );
    uniforms.mainTex = { value: this.texture };
    uniforms.boneTex = { value: this.boneTex };
    uniforms.lightingEnabled = { value: this.lightingEnabled ? 1.0 : 0.0 };

    if (!this.softwarePath){
        // Material
        this.material = new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: vertexShaderMesh,
            fragmentShader: fragmentShaderMesh,
            lights: true
        });
    }
    else{
        if (this.yuvTextures === 0){
            this.initializeYUVTexture(0, 0);
        }

        this.YUV2RGB = [
            1.16438,  0.00000,  1.59603, -0.87079,
            1.16438, -0.39176, -0.81297,  0.52959,
            1.16438,  2.01723,  0.00000, -1.08139,
            0, 0, 0, 1
        ];
        // Material
        this.material = new THREE.ShaderMaterial({
            uniforms:
            {
                mainTex: { value: this.texture },
                boneTex: { value: this.boneTex },
                yTex: { value : this.yuvTextures[0] },
                uTex: { value : this.yuvTextures[1] },
                vTex: { value : this.yuvTextures[2] },
                yTexturePos: { value : new Float32Array(4) },
                uTexturePos: { value : new Float32Array(4) },
                vTexturePos: { value : new Float32Array(4) },
                YUV2RGB: { value : this.YUV2RGB },
            },
            
            //iOS Path
            vertexShader: vertexShaderiOS,
            fragmentShader: fragmentShaderiOS
        });
    }

    this.customDepthMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShaderShadow,
        fragmentShader: fragmentShaderShadow,
    });

    this.material.side = THREE.FrontSide;
    this.material.shadowSide = THREE.DoubleSide;

    this.mesh = new THREE.Mesh( this.geometry, this.material );
    this.mesh.castShadow = true;
    this.mesh.receiveShadow = true;
    this.mesh.customDistanceMaterial = this.customDepthMaterial;
    this.mesh.customDepthMaterial = this.customDepthMaterial;
    this.mesh.frustumCulled = false;
    
    // Add geometry to Scene
    this.scene.add( this.mesh );
  }

  // Render Loop
  render = function () 
  {
    if (!this.overrideRender){
        if (this.renderer.render){
            this.renderer.render(this.scene, this.camera);
        }

        // Necessary so the UI doesn't clear the OMS scene contents
        this.renderer.autoClear = false;
    }
  }

  initializeYUVTexture(width, height){
    var yDataPerRow = width;
    var yRowCnt     = height;
    
    var uDataPerRow = (width / 2);
    var uRowCnt     = (height / 2);
    
    var vDataPerRow = uDataPerRow;
    var vRowCnt     = uRowCnt;

    let textureDataPerRow = [yDataPerRow, uDataPerRow, vDataPerRow];
    let rowCounts = [yRowCnt, uRowCnt, vRowCnt];

    this.yuvTextures = [];
    for (let i = 0; i < 3; i++){
        let data = [];
        for (let j = 0; j < textureDataPerRow[i]; j++){
            data.push(0);
        }
        this.yuvTextures[i] = new THREE.DataTexture(new Uint8Array(data), textureDataPerRow[i], rowCounts[i], THREE.LuminanceFormat , THREE.UnsignedByteType);
        this.yuvTextures[i].generateMipmaps = false;
        this.yuvTextures[i].anisotropy = 1;
        this.yuvTextures[i].minFilter = THREE.LinearFilter;
        this.yuvTextures[i].magFilter = THREE.LinearFilter;
        this.yuvTextures[i].wrapS = THREE.ClampToEdgeWrapping;
        this.yuvTextures[i].wrapT = THREE.ClampToEdgeWrapping;

        this.yuvTextures[i].needsUpdate = true;
    }

    this.updateYUVTexturePos(width, height);
  }

  reinitializeMaterial(){
    // Material
    this.material = new THREE.ShaderMaterial({
        uniforms:
        {
            mainTex: { value: this.texture },
            boneTex: { value: this.boneTex },
            yTex: { value : this.yuvTextures[0] },
            uTex: { value : this.yuvTextures[1] },
            vTex: { value : this.yuvTextures[2] },
            yTexturePos: { value : this.yuvTexturePos[0] },
            uTexturePos: { value : this.yuvTexturePos[1] },
            vTexturePos: { value : this.yuvTexturePos[1] },
            YUV2RGB: { value : this.YUV2RGB },
        },
        
        vertexShader: vertexShaderiOS,
        fragmentShader: fragmentShaderiOS
    });

    this.material.side = THREE.DoubleSide;
    this.mesh.material = this.material;
    this.mesh.customDepthMaterial = this.customDepthMaterial;
    this.mesh.customDistanceMaterial = this.customDepthMaterial;
  }

  updateYUVTexturePos(width, height){
    var yDataPerRow = width;
    var yRowCnt     = height;

    var uDataPerRow = (width / 2);
    var uRowCnt     = (height / 2);

    var vDataPerRow = uDataPerRow;
    var vRowCnt     = uRowCnt;

    var tTop = 0;
    var tLeft = 0;
    var tBottom = height / yRowCnt;
    var tRight = width / yDataPerRow;
    var yTexturePosValues = new Float32Array([tRight, tTop, tLeft, tBottom]);

    tBottom = (height / 2) / uRowCnt;
    tRight = (width / 2) / uDataPerRow;

    var uTexturePosValues = new Float32Array([tRight, tTop, tLeft, tBottom]);

    tBottom = (height / 2) / vRowCnt;
    tRight = (width / 2) / vDataPerRow;

    var vTexturePosValues = new Float32Array([tRight, tTop, tLeft, tBottom]);

    var dataPerRow = (width * 2);
    var rowCnt     = height;

    tTop = 0;
    tLeft = 0;
    tBottom = height / rowCnt;
    tRight = width / (dataPerRow / 2);
    var texturePosValues = new Float32Array([tRight, tTop, tLeft, tBottom]);

    this.yuvTexturePos = [
        yTexturePosValues,
        uTexturePosValues,
        vTexturePosValues
    ];
  }

  updateMeshTexture(textureData, width, height){
    var ylen = width * height;
    var uvlen = (width / 2) * (height / 2);

    let yuvTextureData = {
      yData: textureData[0],
      uData: textureData[1],
      vData: textureData[2]
    };

    if (this.yuvTextures === 0){
        this.initializeYUVTexture(width, height);
    }

    this.yuvTextures[0].image.data = yuvTextureData.yData;
    this.yuvTextures[1].image.data = yuvTextureData.uData;
    this.yuvTextures[2].image.data = yuvTextureData.vData;
    this.yuvTextures[0].needsUpdate = true;
    this.yuvTextures[1].needsUpdate = true;
    this.yuvTextures[2].needsUpdate = true;

    let YUV2RGB = [
        1.16438,  0.00000,  1.59603, -0.87079,
        1.16438, -0.39176, -0.81297,  0.52959,
        1.16438,  2.01723,  0.00000, -1.08139,
        0, 0, 0, 1
    ];

    var yDataPerRow = width;
    var yRowCnt     = height;

    var uDataPerRow = (width / 2);
    var uRowCnt     = (height / 2);

    var vDataPerRow = uDataPerRow;
    var vRowCnt     = uRowCnt;

    var tTop = 0;
    var tLeft = 0;
    var tBottom = height / yRowCnt;
    var tRight = width / yDataPerRow;
    var yTexturePosValues = new Float32Array([tRight, tTop, tLeft, tBottom]);

    tBottom = (height / 2) / uRowCnt;
    tRight = (width / 2) / uDataPerRow;

    var uTexturePosValues = new Float32Array([tRight, tTop, tLeft, tBottom]);

    tBottom = (height / 2) / vRowCnt;
    tRight = (width / 2) / vDataPerRow;

    var vTexturePosValues = new Float32Array([tRight, tTop, tLeft, tBottom]);

    var dataPerRow = (width * 2);
    var rowCnt     = height;

    tTop = 0;
    tLeft = 0;
    tBottom = height / rowCnt;
    tRight = width / (dataPerRow / 2);
    var texturePosValues = new Float32Array([tRight, tTop, tLeft, tBottom]);

    if (!this.omsMaterial){
        this.omsMaterial = new THREE.ShaderMaterial({
            uniforms:
            {
                mainTex: { value: this.texture },
                yTex: { value : this.yuvTextures[0] },
                uTex: { value : this.yuvTextures[1] },
                vTex: { value : this.yuvTextures[2] },
                yTexturePos: { value : yTexturePosValues },
                uTexturePos: { value : uTexturePosValues },
                vTexturePos: { value : vTexturePosValues },
                texturePos: { value : texturePosValues },
                YUV2RGB: { value : YUV2RGB },
                boneTex: { value: this.boneTex }
            },
            vertexShader: vertexShaderiOS,
            fragmentShader: fragmentShaderiOS
        });

        this.omsMaterial.side = THREE.DoubleSide;
        this.omsMaterial.needsUpdate = true;
    }
    
    this.mesh.material = this.omsMaterial;
  }

  updateMeshTextureFromCanvas(canvas)
  {
    if (this.texture === 0)
    {
        this.texture = new THREE.CanvasTexture(canvas);
        this.texture.generateMipmaps = false;
        this.texture.anisotropy = 1;
        this.texture.minFilter = THREE.LinearFilter;
        this.texture.magFilter = THREE.LinearFilter;

        let mat = new THREE.ShaderMaterial({
            uniforms:
            {
                mainTex: { value: this.texture },
                boneTex: { value: this.boneTex },
            },
            vertexShader: document.getElementById('vertex-shader').textContent,
            fragmentShader: document.getElementById('fragment-shader').textContent
        });

        mat.side = THREE.DoubleSide;

        mat.needsUpdate = true;
        this.mesh.material = mat;
    }

    this.texture.needsUpdate = true;
  }

  initializeRequestWorker = async function(){
    await this.requestWorker.retrieveClipDatabase();

    this.requestWorker.populateManifestPreviews(this.requestWorker.clipDatabase[0]);
    this.requestWorker.retrieveManifest(this.requestWorker.clipDatabase[0]);
    this.requestWorker.retrieveStreamingContents();

    let broadwayInstances = [];

    var nodes = document.querySelectorAll('div.broadway');
    for (var i = 0; i < nodes.length; i++) {
        let broadway = new Broadway(nodes[i]);
        broadwayInstances.push(broadway);
    }

    this.requestWorker.broadwayInstances = broadwayInstances;

    this.threeJSPlayer.initializeNewSequence(this.requestWorker.activeProfile);
    this.playerUI.initializeLoadedSegmentDisplay(this.requestWorker.activeProfile);
  }

  getMeshTexture(width, height)
  {
    if (!this.softwarePath){
        var props = this.renderer.properties.get(this.texture);
        var handle = props.__webglTexture;

        if (handle === undefined)
        {
            return undefined;
        }
        return {texture: handle};
    }
    else {
        if (width != this.textureWidth || height != this.textureHeight){
            this.initializeYUVTexture(width, height);
            this.reinitializeMaterial();

            this.textureWidth = width;
            this.textureHeight = height;
        }
        else{
            this.updateYUVTexturePos(width, height);
        }
        return {textures: this.yuvTextures};
    }
  }

  getThreeScene(){
    return this.scene;
  }

  getThreeMesh(){
    return this.mesh;
  }

  getThreeRenderer(){
    return this.renderer;
  }

  getThreeCamera(){
    return this.camera;
  }

  getLightingEnabled(){
    return this.lightingEnabled;
  }

  setLightingEnabled(enabled){
    let canUseLighting = enabled && this.geometry.hasNormals === true;
    this.lightingEnabled = canUseLighting;
    this.material.uniforms.lightingEnabled = {value: canUseLighting ? 1.0 : 0.0};
  }

  destroy(){
    if (this.createdRenderer){
        this.renderer.dispose();
        this.renderer = 0;
    }

    if (this.createdScene){
        this.scene = 0;
    }
    else {
        this.scene.remove( this.mesh );
    }

    this.material.dispose();
    this.material = 0;
  }
}

export default ThreeWrapper;