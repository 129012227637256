// Helper function for getting text contents.
function httpGet(theUrl) {
  var xhr = null;
  xhr = new XMLHttpRequest();
  xhr.open("GET", theUrl, false);
  xhr.send(null);
  return xhr;
}

async function fetchPlusRetry(url, retries = 3) {
  return fetch(url)
         .then(res => {
            if (res.ok) {
                return res;
            }
            if (retries > 0) {
                console.log("Failed to fetch " + url + "\n Trying again, retries remaining: " + retries);
                return fetchPlusRetry(url, retries - 1);
            }
            throw new Error(res.status);
         })
         .catch(error => console.error(`Error in fetch from: ${url}`, error.message));
}
  
class RequestWorker {
  constructor(options) {
    // Bind functions
    this.openManifest           = this.openManifest.bind(this);
    this.openManifestLegacy     = this.openManifestLegacy.bind(this);
    this.parseProfile           = this.parseProfile.bind(this);
    this.getActiveProfile       = this.getActiveProfile.bind(this);
    this.setActiveProfile       = this.setActiveProfile.bind(this);
    this.beginSegmentDownload   = this.beginSegmentDownload.bind(this);
    this.getNextPlaybackSegment = this.getNextPlaybackSegment.bind(this);
    this.updateAdaptiveQuality  = this.updateAdaptiveQuality.bind(this);
    this.canPlayFromSegment     = this.canPlayFromSegment.bind(this);
    this.getSegmentCountToPlay  = this.getSegmentCountToPlay.bind(this);
    this.getDuration            = this.getDuration.bind(this);
    this.setLastPlayedSegment   = this.setLastPlayedSegment.bind(this);
    this.seekTo                 = this.seekTo.bind(this);
    this.clearRequestWorker     = this.clearRequestWorker.bind(this);
    this.destroy                = this.destroy.bind(this);

    // Events
    this.onQualityChanged = 0;

    // Settings
    this.maximumQueuedSegments = 4;
    this.maximumDownloadSpeedHistory = 5;

    // Manifest and Download Management
    this.manifest = 
    {
        url: "",
        responseURL: "",
        profiles: [],
        duration: 0
    };
    this.loadedManifest = false;
    this.activeProfile = 0;
    this.activeDownload = 0;
    this.segmentIndex = -1;
    this.segmentQueue = [];
    this.downloadedInitSegment = false;
    this.totalSegments = 0;
    this.totalSegmentsDownloaded = 0;
    this.lastPlayedSegment = 0;

    // Adaptive Streaming
    this.downloadSpeed = 0;
    this.downloadSpeedHistory = [];
    this.adaptiveStreaming = options.adaptiveStreaming ? options.adaptiveStreaming : true;
    this.seekDownloadCount = 0;
    this.maximumSegmentDistance = 10;
    this.segmentsToBuffer = 4;
    this.excludeProfiles = options.excludeProfiles ? options.excludeProfiles : [];
    this.debugEnabled = options.debugEnabled ? options.debugEnabled : false;
    

    this.iOSPath = false;
    this.softwarePath = false;

    this.activeDownloadMarker = -1;
    this.loadedInitSegment = false;
  }

  // Parse manifests prior to HoloStream 2020.4.3 
  // (before a single manifest was generated for all streaming content on all platforms)
  openManifestLegacy(period, manifestRequest)
  {
    // Parse duration from period.
    for (let i = 0; i < period.attributes.length; i++){
        if (period.attributes[i].name == "duration"){
            // HACK: this is a super naive version of parsing an
            // ISO-8601 Duration. However, in the packager we only
            // use seconds so no negative impact for now.

            var value = period.attributes[i].nodeValue;
            if (value.startsWith("PT") && value.endsWith("S")){
                this.manifest.duration = parseFloat(value.substring(2, value.length - 1));
            }
        }
    }

    let adaptationSet = undefined;
    let audioAdaptation = undefined;
    for (let i = 0; i < period.children.length; i++){
        let adaptation = period.children[i];
        for (let j = 0; j < adaptation.attributes.length; j++){
            if (adaptation.attributes[j].name === "mimeType"){
                if (adaptation.attributes[j].nodeValue.includes("video")){
                    adaptationSet = adaptation;
                }
                else if (adaptation.attributes[j].nodeValue.includes("audio")){
                    audioAdaptation = adaptation;
                }
            }
        }
    }

    if (adaptationSet === undefined){
        console.log("ERROR - No valid adaptation set found in manifest");
        return;
    }

    if (audioAdaptation !== undefined){
        for (let i = 0; i < audioAdaptation.children.length; i++){
            let childElement = audioAdaptation.children[i];
            for (let j = 0; j < childElement.attributes.length; j++){
                if (childElement.attributes[j].name === "id" && childElement.attributes[j].nodeValue === "Audio"){
                    // Traverse the Audio adaptation element's children as follows to get the URL for the audio file:
                    // Representation -> SegmentList -> SegmentURL
                    this.manifest.audioURL = childElement.children[0].children[0].attributes[0].nodeValue;
                }
            }
        }
    }

    // Get base URL
    this.manifest.url = manifestRequest.responseURL;
    let splitParts = this.manifest.url.split("/");
    splitParts.pop();
    let baseURL = splitParts.join("/") + "/";

    // TODO: first element (0) is assumed to be BaseURL, so we skip it.
    // We should probably parse it to confirm that first.
    for (let i = 1; i < adaptationSet.children.length; i++){
        // Parse each representation
        let newProfile = this.parseProfile(baseURL, adaptationSet.children[i]);
        if ((!this.iOSPath && !newProfile.id.endsWith("-iOS"))){
            this.manifest.profiles.push(newProfile);
        }
        else if ((this.iOSPath && newProfile.id.endsWith("-iOS"))){
            this.manifest.profiles.push(newProfile);
        }
    }

    // Sort representations by bandwidth
    this.manifest.profiles = this.manifest.profiles.sort((a,b) => {
        if (parseInt(a.bandwidth) < parseInt(b.bandwidth)){
            return 1;
        }
        else if (parseInt(a.bandwidth) > parseInt(b.bandwidth)){
            return -1;
        }

        if (a.id < b.id){
            return 1;
        }
        else if (a.id > b.id){
            return -1;
        }

        return 0;
    });

    this.totalSegments = this.manifest.profiles[0].segments.length;
    this.loadedManifest = true;
    return true;
  }

  // Manifest Parsing
  async openManifest(url)
  {
    // Reset
    this.manifest.url = url;
    this.manifest.originalURL = url;
    this.manifest.profiles = [];
    this.manifest.duration = 0;
    this.activeDownload = 0;
    this.segmentIndex = -1;
    this.segmentQueue = [];
    this.downloadedInitSegment = false;
    this.totalSegments = 0;
    this.totalSegmentsDownloaded = 0;
    this.downloadSpeed = 0;
    this.downloadSpeedHistory = [];
    this.lastPlayedSegment = 0;
    this.loadedInitSegment = false;

    // Fetch manifest
    let parser = new DOMParser();
    let manifestRequest = await fetchPlusRetry(url);

    if (manifestRequest === undefined || manifestRequest.status !== 200 && manifestRequest.status !== "200"){
        console.error(`Manifest request to ${url} returned status ${manifestRequest.status}`);
        return false;
    }

    let manifestData;

    try {    
        manifestData = await manifestRequest.text();
    } catch(error) {
        console.error(`Error extracting manifest text from: ${url}`, error.message);
    }

    let xmlDoc = parser.parseFromString(manifestData, "text/xml");

    // Parse manifest
    let rootElement = xmlDoc;
    let mpdRoot = rootElement.children[0];
    let period = undefined;
    let title = undefined;

    //Regex for  valid uuid (from https://stackoverflow.com/a/13653180)
    let re = new RegExp('([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})');

    let reResult = url.match(re);
    if (reResult[1]){
        this.manifest.uuid = reResult[1];
    }
    else{
        this.manifest.uuid = "N/A";
    }

    for (let i = 0; i < mpdRoot.children.length; i++){
        if (mpdRoot.children[i].nodeName.toLowerCase() === "period"){
            period = mpdRoot.children[i];
        }
        else if (mpdRoot.children[i].nodeName.toLowerCase() === "title"){
            title = mpdRoot.children[i];
            this.manifest.clipTitle = title.attributes["title"].nodeValue;
        }
    }
    if (title === undefined){
        if (period === undefined){
            console.log("Invalid manifest. No period found");
            return false;
        }

        // If we find a period and no title, this is a legacy manifest and
        // should be parsed as such
        this.openManifestLegacy(period, manifestRequest);
        return true;
    }
    
    // Parse duration from period.
    for (let i = 0; i < period.attributes.length; i++){
        if (period.attributes[i].name == "duration"){
            // HACK: this is a super naive version of parsing an
            // ISO-8601 Duration. However, in the packager we only
            // use seconds so no negative impact for now.

            var value = period.attributes[i].nodeValue;
            if (value.startsWith("PT") && value.endsWith("S")){
                this.manifest.duration = parseFloat(value.substring(2, value.length - 1));
            }
        }
    }

    let adaptationSet = undefined;
    let audioAdaptation = undefined;
    let hlsPlaylistAdaptation = undefined;
    let hlsSegmentAdaptation = undefined;
    for (let i = 0; i < period.children.length; i++){
        let adaptation = period.children[i];
        for (let j = 0; j < adaptation.attributes.length; j++){
            if (adaptation.attributes[j].name === "mimeType"){
                if (this.iOSPath){
                    if (adaptation.attributes[j].nodeValue === "video/x-mpegurl"){
                        hlsPlaylistAdaptation = adaptation;
                        continue;
                    }
                    else if (adaptation.attributes[j].nodeValue === "video/mp2t"){
                        hlsSegmentAdaptation = adaptation;
                    }
                    else if (adaptation.attributes[j].nodeValue === "application/oms"){
                        adaptationSet = adaptation;
                        adaptationSet.iOSOMS = true;
                    }
                    else if (adaptation.attributes[j].nodeValue.includes("audio") && 
                             audioAdaptation === undefined && 
                             adaptation.attributes[j].nodeValue.includes("aac")){
                        audioAdaptation = adaptation;
                    }
                }
                else{
                    let suffix = this.softwarePath == true ? "mp4" : "m4s"; // TODO: software path may also have to be considered for iOS
                    if (adaptation.attributes[j].nodeValue.includes("video") && 
                        adaptationSet === undefined &&
                        adaptation.attributes[j].nodeValue.includes(suffix)){

                        adaptationSet = adaptation;
                    }
                    else if (adaptation.attributes[j].nodeValue.includes("audio") && 
                             audioAdaptation === undefined){
                        audioAdaptation = adaptation;    
                    }
                }
            }
        }
    }

    if (adaptationSet === undefined){
        console.log("ERROR - No valid adaptation set found in manifest");
        return;
    }

    if (audioAdaptation !== undefined){
        for (let i = 0; i < audioAdaptation.children.length; i++){
            let childElement = audioAdaptation.children[i];
            for (let j = 0; j < childElement.attributes.length; j++){
                if (childElement.attributes[j].name === "id" && childElement.attributes[j].nodeValue === "Audio"){
                    // Traverse the Audio adaptation element's children as follows to get the URL for the audio file:
                    // Representation -> SegmentList -> SegmentURL
                    this.manifest.audioURL = childElement.children[0].children[0].attributes[0].nodeValue;
                }
            }
        }
    }

    // Get base URL
    this.manifest.url = manifestRequest.url;

    // Mark the manifest and all subsequent downloads with the current timestamp
    // so that they can be invalidated when a new manifest is loaded.
    this.activeDownloadMarker = Date.now();
    this.manifest.activeDownloadMarker = this.activeDownloadMarker;
    let splitParts = this.manifest.url.split("/");
    splitParts.pop();
    let baseURL = splitParts.join("/") + "/";

    if (this.iOSPath){
        if (hlsPlaylistAdaptation === undefined){
            console.log("ERROR - No valid playlist found in manifest");
            return;
        }

        let playlistSegment = hlsPlaylistAdaptation.children[1].children[0].children[0];
        for (let j = 0; j < playlistSegment.attributes.length; j++){
            if (playlistSegment.attributes[j].nodeName==="media"){
                let hlsURL = playlistSegment.attributes[j].nodeValue;
                this.manifest.hlsURL = baseURL + hlsURL;
            }
        }
    }

    // TODO: first element (0) is assumed to be BaseURL, so we skip it.
    // We should probably parse it to confirm that first.
    for (let i = 1; i < adaptationSet.children.length; i++){
        // Parse each representation
        let newProfile = this.parseProfile(baseURL, adaptationSet.children[i]);
        if (this.excludeProfiles.includes(newProfile.displayName)){
                continue;
        }
        if (adaptationSet.iOSOMS){
            for (let j = 0; j < adaptationSet.children[i].attributes.length; j++){
                if (adaptationSet.children[i].attributes[j].name === "bandwidth"){
                    newProfile.omsBandwidth = adaptationSet.children[i].attributes[j].nodeValue;
                    break;
                }
            }

            for (let j = 0; j < hlsSegmentAdaptation.children[i].attributes.length; j++){
                if (hlsSegmentAdaptation.children[i].attributes[j].name === "bandwidth"){
                    newProfile.hlsBandwidth = hlsSegmentAdaptation.children[i].attributes[j].nodeValue;
                    break;
                }
            }
            
        }
        this.manifest.profiles.push(newProfile);
    }

    // Sort representations by bandwidth
    this.manifest.profiles = this.manifest.profiles.sort((a,b) => {
        if (parseInt(a.bandwidth) < parseInt(b.bandwidth)){
        return 1;
      }
        else if (parseInt(a.bandwidth) > parseInt(b.bandwidth)){
        return -1;
      }

        if (a.id < b.id){
            return 1;
        }
        else if (a.id > b.id){
            return -1;
        }

      return 0;
    });

    if (this.manifest.profiles.length === 0){
        console.error("No valid profiles found in manifest for browser playback.");
        return false;
    }

    this.totalSegments = this.manifest.profiles[0].segments.length;
    this.loadedManifest = true;
    return true;
  }

  // Profile parsing.
  parseProfile(baseURL, representation)
  {
    let newProfile = {
      codecs: 0,
      height: 0,
      width: 0,
      bandwidth: 0,
      duration: 0,
      timescale: 0,
      initializationSegment: 0,
          id: 0,
          displayName: 0,
      segments: []
    };

    let segmentList = representation.children[0];

    // Get codec, height, width, and bandwidth from representation
    for (let i = 0; i < representation.attributes.length; i++){
      if (representation.attributes[i].name === "codecs"){
        newProfile.codecs = representation.attributes[i].nodeValue;
      }
      else if (representation.attributes[i].name === "height"){
        newProfile.height = representation.attributes[i].nodeValue;
      }
      else if (representation.attributes[i].name === "width"){
        newProfile.width = representation.attributes[i].nodeValue;
      }
      else if (representation.attributes[i].name === "bandwidth"){
              newProfile.bandwidth = representation.attributes[i].nodeValue;
      }
      else if (representation.attributes[i].name === "id"){
          newProfile.id = representation.attributes[i].nodeValue;
      }
      else if (representation.attributes[i].name === "displayName"){
          newProfile.displayName = representation.attributes[i].nodeValue;
      }
    }

    for (let i = 0; i < segmentList.attributes.length; i++){
      if (segmentList.attributes[i].name === "duration"){
        newProfile.duration = segmentList.attributes[i].nodeValue;
      }
      else if (segmentList.attributes[i].name === "timescale"){
        newProfile.timescale = segmentList.attributes[i].nodeValue;
      }
    }

    // Get each media segment url
    for (let i = 0; i < segmentList.children.length; i++){
        let segment = segmentList.children[i];
        let newSegment = {
            index:          i,
            initSegment:    false,
            url:            undefined,
            fileSize:       0
        };

        for (let j = 0; j < segment.attributes.length; j++){
          if (segment.attributes[j].name === "media")
          {
            let segmentURL = segment.attributes[j].nodeValue;
            newSegment.url = baseURL + segmentURL;

            if (segmentURL.endsWith("init.mp4")){
              newSegment.initSegment = true;
            }
          }
            
          if (segment.attributes[j].name === "range"){
            newSegment.fileSize = parseInt(segment.attributes[j].nodeValue.split("-")[1]);
          }
        }

        if (newSegment.url !== undefined){
            newProfile.segments.push(newSegment);
        }
    }

    return newProfile;
  }

  // Get/Set Active Profile
  getActiveProfile()
  {
    return this.manifest.profiles[this.activeProfile];
  }

  setActiveProfile(index, adaptive)
  {
    // Do not switch profiles if the init segment has not already been loaded
    if (index < 0 || index >= this.manifest.profiles.length || !this.loadedInitSegment)
    {
        return;
    }

    this.adaptiveStreaming = adaptive;
    this.activeProfile = index;

    if (this.onQualityChanged !== 0)
    {
        this.onQualityChanged(this.manifest.profiles[this.activeProfile]);
    }
  }

  getDuration()
  {
    // Conservative duration estimate, exclude init segment (no duration),
    // and exclude the last segment since we don't know its duration.
    var profile = this.getActiveProfile();
    var segmentCount = (profile.segments.length - 2);
    var segmentDuration = (profile.duration / profile.timescale);
    var conservativeDuration = segmentCount * segmentDuration;

    // If our duration from the manifest is less than our conservative 
     // duration estimate then its obviously wrong.
    if (this.manifest.duration > segmentDuration)
    {
        return this.manifest.duration;
    }

    return conservativeDuration;
  }

  getNextSegmentDownload()
  {
    let profile = this.manifest.profiles[this.activeProfile];

    // Check segment bounds.
    this.segmentIndex += 1;
    if (this.segmentIndex >= profile.segments.length){
        this.segmentIndex = 0;

        if (profile.segments[this.segmentIndex].initSegment && this.downloadedInitSegment){
            this.segmentIndex = 1;
        }
    } 
    else if (this.segmentIndex < 0){
        this.segmentIndex = 0;
    }

    let segment = profile.segments[this.segmentIndex];
    segment.width = profile.width;
    segment.height = profile.height;
    segment.downloadMarker = this.manifest.activeDownloadMarker;

    if (this.debugEnabled){
        console.log(`Next segment to download is ${segment.url} and is marked ${segment.downloadMarker}`);
    }

    return segment;
  }

  // Helper function to async get array buffer.
  beginSegmentDownload(url)
  {
    if (this.activeDownload === 0){
        return;
    }

    let download = this.activeDownload;
    download.startTime = Date.now();

    const debugEnabled = this.debugEnabled;

    fetch(download.url).then(async function(x){
        download.data = await x.arrayBuffer();
        download.finished = true;
        if (debugEnabled){
            console.log(`Segment downloaded: ${download.url}`);
        }
    }).catch(e => {
        console.log(`Segment ${download.url} failed to download with error: ${e.message}`);
    });
  }

  // Update function
  update()
  {
    if (!this.loadedManifest)
    {
        return;
    }

    // Determine how far ahead of playback we are.
    var segmentCount = this.getSegmentCountToPlay(this.lastPlayedSegment);
    this.lastDownloadSegment = Math.max(this.segmentIndex, 0);

    let segmentDistance = 0;
    let playedSegment = this.lastPlayedSegment;
    while(!isNaN(playedSegment) && playedSegment !== this.lastDownloadSegment && segmentDistance < this.maximumSegmentDistance){
        playedSegment = (playedSegment + 1) % this.getActiveProfile().segments.length;
        segmentDistance++;
    }

    // This ensures we are downloading fast enough and also that we don't pointlessly
    // buffer too much. Allow enough downloads after a seek to occur to ensure we have
    // buffered enough from our new playback point before early-outing the update loop.
    if (segmentCount <= -this.segmentsToBuffer && (segmentDistance) >= this.segmentsToBuffer && this.seekDownloadCount <= 0 && !this.forceUpdate)
    {
        // Only bail out early if we do not have a download to handle
        if (this.activeDownload === 0)
        {
            return;
        }
    }

    if (this.activeDownload === 0)
    {
        let segment = this.getNextSegmentDownload();

        if (segment.downloadMarker === this.activeDownloadMarker){
            this.activeDownload = 
            {
                url: segment.url,
                segment: segment,
                data: 0,
                finished: false,
                startTime: 0,
                endTime: 0,
                downloadMarker: segment.downloadMarker
            };
            this.seekDownloadCount--;
            this.beginSegmentDownload();
        }
    }
    else
    {
        if (this.activeDownload.finished && this.segmentQueue.length < this.maximumQueuedSegments)
        {
            if (this.activeDownload.segment.initSegment)
            {
                this.downloadedInitSegment = true;
            }
            else {
                // True when we have downloaded at least one segment that is not an init segment
                this.loadedInitSegment = true;
            }

            if (this.activeDownload.segment.index >= this.totalSegmentsDownloaded)
            {
                this.totalSegmentsDownloaded = this.activeDownload.segment.index + 1;
            }

            this.activeDownload.endTime = Date.now();
            this.updateAdaptiveQuality(this.activeDownload);
            this.segmentQueue.push(this.activeDownload);
            this.activeDownload = 0;
        }
    }
  }

  clearRequestWorker = async function()
  {
    this.loadedManifest = false;
    this.segmentQueue = [];
    this.manifest = 
    {
        url: "",
        responseURL: "",
        profiles: [],
        duration: 0
    };
  }

  // Consume a segment
  getNextPlaybackSegment()
  {
    if (this.segmentQueue.length > 0)
    {
        let segment = this.segmentQueue.shift();
        if (segment.downloadMarker !== this.activeDownloadMarker){
            if (this.debugEnabled){
                console.log(`Download marker did not match, discarding segment ${segment.url}`)
            }
            return undefined;
        }
        else{
            if (this.debugEnabled){
                console.log(`Returning segment as next playback segment: ${segment.url}`)
            }
            return segment;
        }
    }

    return undefined;
  }

  // Adaptive Quality
  updateAdaptiveQuality(download)
  {
    // Calculate bytes per second and add to history.
    var timeInSeconds = (download.endTime - download.startTime) / 1000.0;
    var bytesPerSecond = download.segment.fileSize / timeInSeconds;
    this.downloadSpeedHistory.push(bytesPerSecond);

    // We limit the number of download speeds to consider for average.
    while (this.downloadSpeedHistory.length > this.maximumDownloadSpeedHistory)
    {
        this.downloadSpeedHistory.shift();
    }

    // Average the download speeds.
    var downloadSpeedSum = 0;
    for (let i = 0; i < this.downloadSpeedHistory.length; ++i)
    {
        downloadSpeedSum += this.downloadSpeedHistory[i];
    }
    this.downloadSpeed = downloadSpeedSum / this.downloadSpeedHistory.length;

    // Find appropriate quality based on that calculated download speed.
    // Note: lowest quality is the last in the list, so we start our selection there.
    var adaptiveProfileIndex = this.manifest.profiles.length - 1;
    for (let i = 0; i < this.manifest.profiles.length; ++i)
    {
        var profile = this.manifest.profiles[i];

        if (this.downloadSpeed >= profile.bandwidth)
        {
            adaptiveProfileIndex = i;
            break;
        }
    }

    if (this.adaptiveStreaming)
    {
        this.setActiveProfile(adaptiveProfileIndex, true);
    }
  }

  // Estimate if playback would be possible.
  canPlayFromSegment(index)
  {
    if (this.getSegmentCountToPlay(index) <= 0)
    {
        return true;
    }

    return false;
  }

  getSegmentCountToPlay(index)
  {
    let profile = this.getActiveProfile();

    if (this.downloadSpeedHistory.length > 0)
    {
        let largestSegmentSize = 0;
        let segmentSeconds = profile.duration / profile.timescale;

        // Get the largest segment size.
        for (let i = 0; i < profile.segments.length; ++i)
        {
            largestSegmentSize = Math.max(largestSegmentSize, profile.segments[i].fileSize);
        }

        // Find distance from current segment (this.segmentIndex) to lastDownloadSegment. If it is 
        // less than segmentsToBuffer, then we need to download segments.
        let segmentDistance = 0;
        let currentSegmentNumber = this.lastPlayedSegment;
        while(currentSegmentNumber !== this.lastDownloadSegment && this.lastDownloadSegment !== undefined && segmentDistance < this.maximumSegmentDistance){
            segmentDistance++;
            currentSegmentNumber = (currentSegmentNumber + 1) % profile.segments.length;

            if (segmentDistance > 0 && currentSegmentNumber === this.lastPlayedSegment){
                return -this.segmentsToBuffer;
            }
        }

        // Return a value which will prevent downloads from occurring
        if (segmentDistance >= this.segmentsToBuffer || currentSegmentNumber === this.lastPlayedSegment){
            return -this.segmentsToBuffer;
        }

        // Calculate how much time it will take for us to download the next required segments.
        let downloadTimeRemaining = segmentDistance * (largestSegmentSize / this.downloadSpeed);

        // Calculate how long it will take for us to play the rest of the video.
        let playbackTimeRemaining = segmentDistance * segmentSeconds;

        // Calculate how many segments we need to buffer before we can reach the end of playback.
        // This can be a negative number at which point we're outpacing the playback.
        let segmentsRemainingToPlay = Math.floor((downloadTimeRemaining - playbackTimeRemaining) / segmentSeconds);
        return Math.min(segmentsRemainingToPlay, profile.segments.length);
    }

    return profile.segments.length;
  }

  setLastPlayedSegment(index)
  {
    this.lastPlayedSegment = index;
  }

  seekTo(percentage)
  {
    this.seekDownloadCount = 3;
    var totalSegments = this.getActiveProfile().segments.length;
    this.segmentIndex = Math.floor(totalSegments * percentage) - 2;

    this.lastPlayedSegment = this.segmentIndex + 2;
    this.lastDownloadSegment = this.segmentIndex + 2;
  }

  destroy(){
    this.clearRequestWorker();
    this.activeDownload = 0;
  }
}

export default RequestWorker;