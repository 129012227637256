import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { HoloStream } from "../";
import PlayerUI from "../holostream/PlayerUI";

class PlaybackManager {
  constructor(options) {
    this.clips = options.clips;
    this.firstClip = options.firstClip;
    this.threeScene = options.threeScene;
    this.threeCamera = options.threeCamera;
    this.threeRenderer = options.threeRenderer;
    this.showPlayers = options.showPlayers;

    this.maxHoloStreams = 5;
    this.holoStreams = {};

    window.playbackManager = this;

    this.renderButton = this.renderButton.bind(this);
  }

  createHoloStream(clipName, hide) {
    let holostreamOptions = {
        debugEnabled: false,
        targetContainerDivID: "holostream-player",
        instanceID: null,
        targetCanvasID: "holostreamCanvas",
        hideUI: true,
        threeScene: this.threeScene,
        threeCamera: this.threeCamera,
        threeRenderer: this.threeRenderer,
    };

    let holoStream = new HoloStream(holostreamOptions);

    if (this.showPlayers) {
        holoStream.showVideoPlayer();
    }

    holoStream.openURL(this.clips[clipName].manifestURL);

    if (!this.playerUI) {
        // Hack (MS): use playerUI to construct a bufferingSpinner, hide the other controls.
        this.playerUI = new PlayerUI({}, "holostream-player", {}, holoStream);
        const containerDiv1 = document.getElementById("hsp-controls-container");
        if (containerDiv1) {
            containerDiv1.remove();
        }
        const containerDiv2 = document.getElementById("hsp-large-play-container");
        if (containerDiv2) {
            containerDiv2.remove();
        }
        this.bufferingSpinner = document.getElementById("hsp-buffering-container");
        this.bufferingSpinner.style.visibility = "hidden";
    }
    
    holoStream.OMSPlaybackReadyFunctions.push({
        update: () => {
            // Note (MS): line can be uncommented for testing performance.
            // console.log("clip " + clipName + " is ready for playback");
            this.holoStreams[clipName].status = "ready";
        }
    });

    if (hide) {
        holoStream.threeWrapper.mesh.position.x = 10000;
    }

    this.holoStreams[clipName] = {
        holoStream: holoStream,
        status: "loading"
    };
  }

  // preload up to this.maxHoloStreams holoStreams including a clip and its following clips recursively
  preloadHoloStreams(name, clipToKeep) {
    let clipsToPreload = [name];
    let nextClips = this.clips[name].nextClips;
    clipsToPreload.push(...nextClips);
    while (clipsToPreload.length < this.maxHoloStreams) {
        let nextNextClips = [];
        for (const nextClip of nextClips) {
            for (const nextNextClip of this.clips[nextClip].nextClips) {
                if (!nextNextClips.includes(nextNextClip) && !clipsToPreload.includes(nextNextClip)) {
                    nextNextClips.push(nextNextClip);
                }
            }
        }
        if (nextNextClips.length === 0) {
            break;
        }
        clipsToPreload.push(...nextNextClips);
        nextClips = nextNextClips;
    }
    clipsToPreload = clipsToPreload.slice(0,this.maxHoloStreams);

    // first remove other clips
    for (const [clipName, clipData] of Object.entries(this.holoStreams)) {
        if (clipName !== clipToKeep && !clipsToPreload.includes(clipName)) {
            // Note (MS): calling openURL without destroying a pre-existing HoloStream was failing here.
            // The cause seems to be related to the HoloStream having hideUI set as true,
            // and having finished playback (but not looping).
            // Destroying and recreating seems to work fine, but this could be revisited if causing performance issues
            clipData.holoStream.destroyHoloStream();
            delete this.holoStreams[clipName];
        }
    }

    for (const clipName of clipsToPreload) {
        if (!(clipName in this.holoStreams)) {
            this.createHoloStream(clipName, name !== clipName);
        }
    }
  }

  renderButton(clipName, clipToHide) {
    let button = document.createElement("button");
    button.setAttribute("class", "next-clip-button");
    let container = document.getElementById("holostream-player-container");
    container.appendChild(button);
    button.innerHTML = 'Play ' + clipName;
    button.onclick = function() {
        // hide buttons and clip
        var buttons = document.getElementsByClassName('next-clip-button');
        while(buttons[0]) {
            buttons[0].parentNode.removeChild(buttons[0]);
        }
        
        // play next clip
        window.playbackManager.playHoloStream(clipName, clipToHide);
        window.playbackManager.preloadHoloStreams(clipName, clipToHide);    
    };
  }

  hideClip(clipToHide) {
      let holoStream = this.holoStreams[clipToHide].holoStream;
      holoStream.getThreeMesh().visible = false;
  }

  playHoloStream(name, clipToHide) {
      let holoStreamData = this.holoStreams[name];
      if (!holoStreamData) {
          alert ("Clip " + name + " not yet loaded. Press OK to retry.");
          setTimeout(function() { window.playbackManager.playHoloStream(name); }, 30);
          return;
      }
      let holoStream = holoStreamData.holoStream;
      let status = holoStreamData.status;
      if (status !== "ready") {
          this.bufferingSpinner.style.visibility = "visible";
          if (this.bufferingSpinner.bufferingImage) {
              this.bufferingSpinner.bufferingImage.setAttribute("class", "hsp-buffering-icon");
          }
          setTimeout(function() { window.playbackManager.playHoloStream(name); }, 30);
          return;
      }
      this.bufferingSpinner.bufferingImage.setAttribute("class", "hsp-buffering-icon hsp-transparent");
      
      if(clipToHide) {
          this.hideClip(clipToHide);
      }
      holoStream.threeWrapper.mesh.position.x = 0;
      holoStream.handlePlay(true);

      if (this.clips[name].nextClips.length === 0) {
          holoStream.clipEndFunctions = [];
          holoStream.clipEndFunctions.push(
            { 
                onClipEnd: () => 
                {
                    // Note (MS): line can be uncommented for testing.
                    //console.log("last clip (" + name + ") ended");
                    holoStream.videoWorker.pause();
                }
            }
          );
      }
      else if (this.clips[name].nextClips.length === 1) {
          holoStream.clipEndFunctions = [];
          holoStream.clipEndFunctions.push(
            { 
                onClipEnd: () => 
                {
                    // Note (MS): line can be uncommented for testing.
                    // console.log("clip " + name + " ended");
                    this.playHoloStream(this.clips[name].nextClips[0], name);
                    this.preloadHoloStreams(this.clips[name].nextClips[0], name);
                }
            }
          );
      }
      else if (this.clips[name].nextClips.length === 2) {
          holoStream.clipEndFunctions = [];
          holoStream.clipEndFunctions.push(
            { 
                onClipEnd: () => 
                {
                    // Note (MS): line can be uncommented for testing.
                    // console.log("clip " + name + " ended");
                    this.renderButton(this.clips[name].nextClips[0], name);
                    this.renderButton(this.clips[name].nextClips[1], name);
                }
            }
          );
      }
  }

  loadClips() {
      this.preloadHoloStreams(this.firstClip);
  }

  play() {
      this.playHoloStream(this.firstClip);
  }

  destroyClips() {
    for (const [clipName, clipData] of Object.entries(this.holoStreams)) {
        clipData.holoStream.destroyHoloStream();
        delete this.holoStreams[clipName];
    }
  }
}

export default PlaybackManager;